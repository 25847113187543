<script setup>
import { onMounted, ref } from 'vue'
import { X } from 'lucide-vue-next'
import { useScroll } from '@vueuse/core'

defineProps({
  overflowVisible: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['close'])
const show = ref(false)
const bodyRef = ref(null)
const { arrivedState } = useScroll(bodyRef)

function close() {
  show.value = false

  setTimeout(() => {
    emit('close')
  }, 200)
}

onMounted(() => {
  setTimeout(() => {
    show.value = true
  }, 100)
})
</script>

<template>
  <Teleport to="#app">
    <div
      class="w-full min-h-full absolute top-0 left-0 transition-colors duration-200 flex justify-center items-center z-50"
      :class="{ 'bg-black/50': show, 'bg-transparent': !show }"
      @click.self.prevent="close"
    >
      <div
        class="fixed top-1/2 w-fit bg-white dark:bg-brand-dark rounded shadow transition-all duration-200 border border-transparent dark:border-brand-light"
        :class="{ '-translate-y-96 opacity-0': !show, '-translate-y-1/2': show }"
      >
        <div class="flex items-center justify-between p-3" :class="{ shadow: !arrivedState.top }">
          <h1 class="font-semibold">
            <slot name="title"></slot>
          </h1>
          <button
            type="button"
            @click="close"
            class="p-0.5 text-gray-500 dark:text-brand-extra-light hover:text-black rounded hover:bg-gray-300 dark:hover:bg-brand-light dark:hover:text-brand"
          >
            <X :size="20" />
          </button>
        </div>
        <div
          ref="bodyRef"
          class="px-3 pb-3 min-w-64 min-h-32 max-h-[90vh] overflow-y-auto scrollbar scrollbar-thumb-gray-300 dark:scrollbar-thumb-brand-light"
          :class="{ '!overflow-visible': overflowVisible }"
        >
          <slot></slot>
        </div>
      </div>
    </div>
  </Teleport>
</template>
